
export function getDashboardUrl(path = '', includeReturnPath = false) {
  const config = useRuntimeConfig();
  const requestURL = useRequestURL();

  let url = `https://${requestURL.hostname}`;

  if (includeReturnPath) {
    const route = useRoute();

    url += route.path;
  }

  return `${config.public.dashboardUrl}${path}?origin=${encodeURIComponent(url)}`;
}
